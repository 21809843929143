import React from 'react';
import {configData} from "../../config.js";
import '../../styles.scss';

import {formatDate, fetchSigned, convertToDate,
  isRecentMessage, createTimedMessage, extractTimedMessage} from '../../shared/Utilities.js'
import DelayedTooltip from "../general/DelayedTooltip.js";
import {
  OwcTable, OwcTableHeader, OwcTableHeaderCell,
  OwcTableBody, OwcTableRow, OwcTableCell,
  OwcTypography, OwcPagination,
  OwcInput, OwcIconButton, OwcButton, OwcIcon, OwcProgressSpinner,
} from '@one/react';
import TextWithLookup from '../general/TextWithLookup.js';
import DeviceDetails from '../device/DeviceDetails.js';

/**
 * The Applicable devices page control for searching within use case applicable devices
 *
 * @copyright Roche 2024
 * @author Nick Draper
 */
class ApplicableDevices extends React.Component {
  /**
   * Constructor 
   * 
   * @param props The properties passed
   */
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      systemTypeList: [],
      countryList: [],
      systemTypeListComplete: [],
      systemTypeFilter: "All",
      countryFilter: "All",
      sortList: ["Serial No",
        "System Type",
        "Current Lab Id",],
      searchList: ["Serial No",
        "Current Lab Id",
        "Current Device Location",
        "Current Account No",],
      useCaseSessionId: null,
      page: 1,
      rowsPerPage: 10,
      totalPages: 1,
      displayDeviceDetails: null,
      totalResultCount: null,
      filteredResultCount: null,
      cancelRequested: false,
    };
    this.state = this.clearFilters(this.state);
  }

  /**
   * Simple delay function
   * @param {*} milliseconds The number of seconds to wait for
   * @returns A promise that will resolve after the delay
   */
  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }


  /*** helper method to set internal state lists that are slightly different from those in the use case
   * @param inputDict optional a dictionary to make the changes to
   */
  setUseCaseState(inputDict={}){
    //populate the country list with just those used in the use case
    let countryList = ["All"];
    if (Array.isArray(this.props.useCase.countryList)) {
        countryList = countryList.concat(this.props.useCase.countryList);
        inputDict.countryFilter = countryList[0];
    }
    inputDict.countryList = countryList;

    //populate the system type filter with just those used in the use case
    let systemTypeList = [];
    if (Array.isArray(this.props.useCase.systemTypeList)) {
      //populate the system_type_list
      this.props.useCase.systemTypeList.forEach(system_ref_list_id => {
        const refDataItem = this.state.systemTypeListComplete.find(({ refListId }) => refListId === system_ref_list_id);
        //append the record
        if (refDataItem !== undefined) {
          systemTypeList.push(refDataItem);
        }
      });
      systemTypeList = systemTypeList.sort((a, b) => (a.description > b.description) ? 1 : -1);
      if (this.props.useCase.systemTypeList.length!==1){
        systemTypeList.unshift({refListId:-1,description:"All", isActive:true});
        inputDict.systemTypeFilter = this.props.useCase.systemTypeList[0];
      }
    }
    inputDict.systemTypeList = systemTypeList;
    if (inputDict.systemTypeList.length > 0) {
      inputDict.systemTypeFilter = systemTypeList[0];
    }

    return inputDict;

  }
  /*** helper method to clear all of the filter options and return the dictionary to be applied to the state
   * @param inputDict optional a dictionary to make the changes to
   */
  clearFilters(inputDict={}){
    inputDict.searchBy = "Serial No";
    inputDict.searchTerm = "";
    inputDict.sortBy = "Serial No";
    inputDict.systemTypeFilter = "All";
    inputDict.countryFilter = "All";
    return inputDict;
  }

  /** Runs whenever the properties of the control are changed
   * @param prevProps The previous properties dictionary
   * @param prevState The previous state dictionary
   */
  componentDidUpdate(prevProps, prevState) {
    // we have been signalled to update or the use case has updated
    if ((this.props.useCase !== prevProps.useCase) ||
        (this.props.updateFlag !== prevProps.updateFlag)) {
          if (this.state.systemTypeListComplete.length !== 0) {
          const newState = this.setUseCaseState();
          this.setState(newState, () => this.loadSearchResults());
          }
    }
  }

  /**
   * Runs one after construction after everything is initialised
   */
  componentDidMount() {
    // load the reference data and split out the system type list
    fetchSigned(configData.REFDATA_API_URL + "?includeInactive=true")
      .then(res => res.json())
      .then(
        (result) => {
          const filteredsystemTypeList = result.filter(value =>
            value.type === "Reference System Type"
          );
          this.setState({ systemTypeListComplete: filteredsystemTypeList },
            () => this.setState(this.setUseCaseState())
          );
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error
          });
        }
      )

    this.loadSearchResults();
  }

  /**
   * Load the results of a search from the API into the state
   * @param {*} resetPage 
   */
  loadSearchResults(resetPage=true) {

    this.setState({
      isLoaded: false,
      page: resetPage? 1: this.state.page,
      error: null
    });
    //gather searchData
    const searchData = {
      useCaseId: this.props.useCase.useCaseId,
      versionNo: this.props.useCase.versionNo===null? -1 : this.props.useCase.versionNo,
      searchBy: this.state.searchBy,
      searchTerm: this.state.searchTerm,
      sortBy: this.state.sortBy,
      systemTypeFilter: this.getIdForRefData(this.state.systemTypeFilter, this.state.systemTypeListComplete),
      countryFilter: this.state.countryFilter,
      limit: this.state.rowsPerPage,
      offset: (this.state.page - 1) * this.state.rowsPerPage,
      useCaseSessionId: this.state.useCaseSessionId,
    };
    console.log(`Applicable Device Search data: ${JSON.stringify(searchData)}`);

    fetchSigned(configData.USECASE_API_URL + "device-search/", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(searchData)
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.status === "STARTING") {
            // extract the session id and poll until completed
            searchData.useCaseSessionId = result.useCaseSessionId;
            this.checkForCompletion(searchData);
          } else if (result.status === "SUCCEEDED") {
            // the results are ready
            this.processResults(result);
          } else {
            // error case
            console.log(`Status ${result.status} received`);
            this.setState({error:  "Error searching for devices " + result.error,
                           isLoaded: true,
                           items: []})
          }
          
          
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error: "Error searching for devices " + error,
            isLoaded: true,
            items: [],
          });
        }
      )
  }

  /** Polls for a when the applicable device data is completed
   * @param {*} searchData The search data with the sesssion id
   */
  async checkForCompletion(searchData) {
    const delay = 1000;
    const maxTries = 15 * 60;
    let jobCompleted = false;
    let loopIndex = 0;
    while (jobCompleted === false && loopIndex < maxTries && this.state.cancelRequested === false) {
      // check status
      const statusResponse = await fetchSigned(configData.USECASE_API_URL + "device-search/", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(searchData)
      });
      if (statusResponse !== undefined) {
        const statusResponsejson = await statusResponse.json();
        console.log(`Job status ${statusResponsejson.status}, check ${loopIndex} of ${maxTries}`);

        if (statusResponsejson.status !== "RUNNING") {
          jobCompleted = true;
          if (statusResponsejson.status === "SUCCEEDED") {
            await this.processResults(statusResponsejson);
          } else {
            // error case
            console.log(`Status ${statusResponsejson.status} received`);
            this.setState({error:  "Error searching for devices " + statusResponsejson.error,
                           isLoaded: true,
                           items: []});
            };
        } else {
          if (this.state.cancelRequested === false) {
            this.setState({requestStatus: "Generating Data Product"});
            await this.sleep(delay);
          }
        }
        loopIndex++;
      } else {
        loopIndex++;
        console.log(`No response from use case api attempt ${loopIndex}`);
        await this.sleep(delay);
      }
    };

    if (loopIndex >= maxTries) {
      // timed out
      console.log(`Timed out after attempt ${loopIndex} attempts`);
      this.setState({error:  `Error searching for devices: Timed out after attempt ${loopIndex} attempts`,
                     isLoaded: true,
                     items: []});
    };
  }


  /**
   * Processes the query results and stores them into the state
   * @param {*} result The results from the use case api
   */
  async processResults(result) {
    const maxPage = Math.ceil(result.filteredResultCount / this.state.rowsPerPage);
      result.searchResults.forEach((device, index) => {
        let minDate = null;
        let maxDate = convertToDate("1900-01-01");

        if (typeof device.inclusionDates === 'string') {
          try {
              // Parse the string into an array of objects
              device.inclusionDates = JSON.parse(device.inclusionDates);
          } catch (error) {
              console.error("Failed to parse inclusionDates:", error);
              device.inclusionDates = [];
          }
      }

        device.inclusionDates.forEach((dateRange) => {
          const fromDate = convertToDate(dateRange.from);
          const toDate = convertToDate(dateRange.to);
          if (fromDate < minDate || minDate === null) {
            minDate = fromDate;
          }
          if (toDate > maxDate || toDate === null) {
            maxDate = toDate;
          }
        })
        result.searchResults[index].dateLimit = `${formatDate(minDate)}\nto\n${maxDate===null?"current":formatDate(maxDate)}`;
      });

      const updatedCountryList = this.appendEntries(this.state.countryList, result.countryList);

      this.setState({
        isLoaded: true,            
        totalPages: maxPage,
        items: result.searchResults,
        filteredResultCount: result.filteredResultCount,
        totalResultCount: result.totalResultCount,
        useCaseSessionId: result.useCaseSessionId,
        countryList: updatedCountryList
      });
      console.log(`total results: ${result.totalResultCount}, filtered results: ${result.filteredResultCount}, returned ${result.searchResults.length}`);
      console.log(`rows per page: ${this.state.rowsPerPage}, max page ${maxPage}`);
  }

  appendEntries(originalList, additionalList) {
    let valuesToAdd = [];
    additionalList.forEach(value=>{if (!originalList.includes(value)) {
      valuesToAdd.push(value);
    }})

    if (valuesToAdd.length === 0){
      return originalList;
    } else {
      let newList = [...originalList, ...valuesToAdd];
      // remove the all isAssertEntry, sort and re-add it.
      newList = newList.filter(e => e !== 'All');
      newList = newList.sort();
      newList.unshift('All');
      return newList;
    }
  }

  /**
   * Gets the id for a ref data description
   * @param {*} value the description
   * @param {*} refdataList the list to search
   * @returns the ref list id or null if not found
   */
  getIdForRefData(value, refdataList) {
    let retVal = null;
    if (typeof value === 'string' || value instanceof String)
    {
      const searchText = value.endsWith(configData.REFDATA_DEPRECATED)? 
                            value.slice(0, -configData.REFDATA_DEPRECATED.length)
                            : value;

      const refDataItem = refdataList.find(({ description }) => description === searchText);
      //get the record
      if (refDataItem !== undefined) {
        retVal = refDataItem.refListId;
      }
    }
    return retVal;
  }

  /**
   * Handles clicks on the reset button
   */
  handleResetClick() {
    const newState = this.clearFilters();
    this.setState(newState, ()  => this.loadSearchResults());
  }

  /**
   * Handles Changes to the pagination controls for the results
   * @param {*} ev The event object
  */
  handlePageChange(ev) {
    const stateChanges = {};
    if (ev.pageSize !== this.state.rowsPerPage) {
      stateChanges.rowsPerPage = ev.pageSize;
      stateChanges.page = 1;
    }
    if (ev.page !== this.state.page) {
      stateChanges.page = ev.page;
    }
    //if there are any state changes
    if (stateChanges)
    {
      this.setState(stateChanges, () => this.loadSearchResults(false));
    }
  }

  /**
   * Handles changes to the system type filter
   * @param {*} ev The event object
   */
  handleSystemTypeFilterChange(ev) {
    if (ev.target.value !== this.state.systemTypeFilter) {
      this.setState({ systemTypeFilter: ev.target.value }, () => this.loadSearchResults());
    }
  }

  /**
   * Handles changes to the sort dropdown list
   * @param {*} ev The event object
   */
  handleSortChange(ev) {
    if (ev.target.value !== this.state.sortBy) {
      this.setState(
        {
          sortBy: ev.target.value,
          page: 1,
        },
        () => this.loadSearchResults());
    }
  }

  /**
   * Handles clicks on the search button
   * @param {*} ev The event object
   */
  handleSearchClick(ev) {
    this.loadSearchResults();
  }

  /**
   * Handles pressing enter in the search box to start a search
   * @param {*} ev The event object
   */
  handleSearchKeyUp(ev) {
    // Number 13 is the "Enter" key on the keyboard
    this.setState({ searchTerm: ev.target.value });
    if (ev.keyCode === 13) {
      // Cancel the default action, if needed
      ev.preventDefault();
      // Start the search
      this.loadSearchResults();
    }
  }

  /**
   * Handles requests to display the device details for the selected id
   * @param {*} ev The event object
   * @param {*} deviceId The device id selected
   */
  handleDetailsClick(ev, deviceId) {
    this.setState({displayDeviceDetails:createTimedMessage(deviceId)});
  }

  /**
   * Gets the account text to displaty for this device
   * @param {*} item The device data
   * @returns The account text to display wrapped in a span
   */
  getAccountsText(item) {
    const messageList = [];
    if (item.shipToAccountNo !== null) {
      messageList.push("shipTo: " + item.shipToAccountNo);
    }
    if (item.soldToAccountNo !== null) {
      messageList.push("soldTo: " + item.soldToAccountNo);
    }
    if (item.billToAccountNo !== null) {
      messageList.push("billTo: " + item.billToAccountNo);
    }
    if (item.instrumentLocationAccountNo !== null) {
      messageList.push("Location: " + item.instrumentLocationAccountNo);
    }
    if (messageList.length > 0){
      return(
        <span>
          {messageList.map((message,index) =>(<pre key={item.deviceId + index} style={{marginTop:"0",marginBottom:"0"}}>{message}</pre>))}
        </span>);
    } else {
      return(
        <span style={{overflowWrap:"normal"}}>
          None
        </span>
      );
    }
  }

  /**
   * Renders the agreements table control
   * @returns The JSX of the controls
   */
  renderTable() {
    const error = this.state.error;
    const isLoaded = this.state.isLoaded;
    const items = this.state.items;

    if (error) {
      return <div style={{ paddingTop: "1em", paddingBottom: "23em" , color: "red"}}>Error: {error}</div>;
    } else if (this.state.items.length===0  && isLoaded === false){
      // if there is no data the table does not show the progress spinner correctly
      return (
        <div key={"AgreementNavigationProgressSpinnerDiv"} 
            style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
          <OwcProgressSpinner key={"AgreementNavigationProgressSpinner"}></OwcProgressSpinner>
        </div>
      );
    } else {
      return (
        <OwcTable key={"AgreementNavigationTable"} size='dense' elevated showRowExpanderColumn loading={(this.state.items.length===0  && isLoaded === false)}>
          <OwcTableHeader sticky>
              <OwcTableHeaderCell key="titleSerialNo" resizable width="11%">Serial No</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleSystemType" resizable width="14%">Ref System Type</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleApplicableLimits" resizable width="14%">Applicable Limits</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleLabId" resizable width="14%">Current Lab Id</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleAccountNo" resizable width="14%">Current Account No</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleAccountName" resizable width="14%">Current Account Name</OwcTableHeaderCell>
              <OwcTableHeaderCell key="titleLocation" resizable>Current Device Location</OwcTableHeaderCell>
          </OwcTableHeader>
          <OwcTableBody loading={!isLoaded}>
            {items.map(item => this.renderTableRow(item))}
          </OwcTableBody>
        </OwcTable>
      );
    }
  }

  /**
   * Renders a for for a device
   * @param {*} item The device data
   * @returns The JSX of the controls
   */
  renderTableRow(item){
    const keyEnding = item.deviceId;

    let tooltip = "";
    let rowColour = configData.COLOUR_WHITE;
    
    return (
      <OwcTableRow
          key={"row" + keyEnding}
          id={"row" + keyEnding} 
          expandable 
          style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour}: {}}
          ondblclick={(ev) => this.handleDetailsClick(ev, item.deviceId)}>
        <OwcTableCell 
            style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, whiteSpace:"nowrap"}: {whiteSpace:"nowrap"}} 
            key={"serialNoCell" + keyEnding} valign="middle">
          <TextWithLookup text={item.serialNo}
              id={keyEnding}
              onClick={(ev) => this.handleDetailsClick(ev, item.deviceId)}/>
            {item.current !== undefined && item.current === false
              ? <OwcIcon key={"serialNoHistoryIcon" + keyEnding} name="history"/>
              : ""
            }
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"systemTypeCell" + keyEnding} valign="middle">
          {item.referenceSystemType + (item.referenceSystemTypeIsActive===false?configData.REFDATA_DEPRECATED:"")}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, whiteSpace:"pre-wrap"}: {whiteSpace:"pre-wrap"}} 
            key={"dateLimitsCell" + keyEnding} valign="middle">
          {item.dateLimit}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour}: {}} 
            key={"labIdCell" + keyEnding} valign="middle">
          {item.labId}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"accountNoCell" + keyEnding} id={"accountNoCell" + keyEnding} valign="middle">
          {this.getAccountsText(item)}
        </OwcTableCell>
        <DelayedTooltip key={"accountNoCellTooltip" + keyEnding}
          anchor={"accountNoCell" + keyEnding} 
          disableTriggers = {(item.installedBaseMatchCount === 1)}>
            {item.installedBaseMatchCount === 1?
              ""
            :
              (<>Device details not enriched as this mapped to {item.installedBaseMatchCount} installed base records</>)
            }
        </DelayedTooltip>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"accountNameCell" + keyEnding} valign="middle">
          {item.locationAccountName}
        </OwcTableCell>
        <OwcTableCell style={rowColour !== configData.COLOUR_WHITE? {backgroundColor: rowColour, wordBreak:"normal"}: {wordBreak:"normal"}} 
            key={"instrumentAddressCell" + keyEnding} valign="middle">
          {item.instrumentAddress}
        </OwcTableCell>
        <div key={"expandedRow" + keyEnding} slot="expanded"
          style={{"display": "flex", flexDirection:"column"}}
        >
            <b>Applicable Periods:</b>
            <table><tbody><tr><td width="10%">From</td><td>To</td></tr>
            {item["inclusionDates"].map(incDate => (
              <tr>
                <td>{formatDate(incDate.from)}</td>
                <td>{formatDate(incDate.to)}</td>
              </tr>
            ))}
            </tbody></table>
        </div>
        <DelayedTooltip key={"rowAgreementToolTip" + keyEnding}
            disableTriggers = {(tooltip === "")}
            anchor={"row" + keyEnding}>
         {tooltip}
        </DelayedTooltip>
      </OwcTableRow>
    );
  }

  /**
 * Renders the tab specific filter controls for the Map to Accounts Tab
 * @returns The JSX of the controls
 */
  renderSelectFilter(title, stateKey, optionList, widthString="100%") {
    return(
      <>
        <td> <OwcTypography>{title}</OwcTypography> </td>
        <td>
          <select style={{ width: widthString, position: "relative", zIndex:"2"}}
              value={this.state[stateKey]}
              onChange={(ev) => {
                  if (ev.target.value !== this.state[stateKey]) {
                  const newState = {};
                  newState[stateKey] = ev.target.value;
                  this.setState(newState, () => this.loadSearchResults());
                }
              }}
              disabled={optionList.length < 2}>
            {optionList.map((item,index) => (
              <option key={"filter" + stateKey + index}>{item}</option>
            )
            )}
          </select>
        </td>
      </>
    );
  }
  
  /**
   * Renders the affiliate filter control
   * @returns The JSX of the controls
   */
  renderSearchAndSort() {
    const tableMinHeight = "8em";
    let formattedSystemTypeList = this.state.systemTypeList.map(item=>item.description + (item.isActive===false?configData.REFDATA_DEPRECATED:""));
    console.log(formattedSystemTypeList);
    return (
      <div style={{display:"flex", justifyContent:"space-between", 
                   marginTop:"0.5em", marginRight:"1em", alignItems: "flex-start"}}>
        <div style={{border:"1px solid lightgrey", position: "relative", zIndex:"0"}}>
          <div style={{
                position: "absolute",
                top: "-0.8em",
                fontSize: "0.75em",
                backgroundColor: "white",
                border: "1px solid white", zIndex:"1"
              }}>
            Search
          </div>
          <table style={{minHeight:tableMinHeight}}>
            <tbody>
              <tr>
                <td> <OwcTypography style={{position: "relative", zIndex:"2"}}>Search by:</OwcTypography> </td>
                <td colSpan="2">
                  <select style={{ width: "100%", position: "relative", zIndex:"2" }}
                      value={this.state.searchBy}
                      onChange={(ev) => { this.setState({ searchBy: ev.target.value }) }} >
                    {this.state.searchList.map(item => (<option key={"NavSearch" + item}>{item}</option>))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>
                    <OwcInput
                        style={{ width: "100%" }}
                        placeholder="Press Enter to Search..."
                        round={false}
                        variant="filled"
                        no-clear-icon
                        value={this.state.searchTerm}
                        onValueChange={(ev) => this.setState({ searchTerm: ev.detail })}
                        onKeyUp={(ev) => this.handleSearchKeyUp(ev)}>
                      <OwcIconButton slot="prefix" icon="search" onClick={(ev) => this.handleSearchClick(ev)} />
                    </OwcInput>
                </td>
                <td>
                    <OwcButton style={{marginLeft:"0.5em"}} onClick={(ev) => this.handleSearchClick(ev)}>Search</OwcButton>
                </td>
              </tr>
              <tr>
                <td> <OwcTypography>Sort by:</OwcTypography> </td>
                <td>
                  <select style={{ width: "100%" }}
                      value={this.state.sortBy}
                      onChange={(ev) => this.handleSortChange(ev)}>
                    {this.state.sortList.map(item => (
                      <option key={"NavSort" + item}>{item}</option>
                    ))}
                  </select>
                </td>
                <td align="right"> 
                  <OwcButton  style={{marginLeft:"0.5em", width:"auto"}} onClick={() => this.handleResetClick()}>Reset</OwcButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <div style={{border:"1px solid lightgrey", position: "relative", zIndex:"0"}}>
          <div style={{
              position: "absolute",
              top: "-0.8em",
              fontSize: "0.75em",
              backgroundColor: "white",
              border: "1px solid white", zIndex:"1"}}>
            Filter by:
          </div>
          <div style={{display:"flex", alignItems:"flex-start"}}>
            <table style={{minHeight: tableMinHeight}}><tbody>
            <tr>
            {this.renderSelectFilter("Ref System Type:","systemTypeFilter", formattedSystemTypeList)}
            </tr>
            <tr>
            {this.renderSelectFilter("Current Country:","countryFilter", this.state.countryList)}
            </tr>
          </tbody></table>
          </div>
        </div>
      </div>
    );
  }

  getEmptyAgreementListMessage() {
    const hints = [];
    if (this.props.useCase.dataAccessApproved === null || this.props.useCase.dataAccessApproved === false) {
      hints.push("data access is not approved");
    }
    if (this.props.useCase.dataAccessApproved === true && this.props.useCase.dataEntitlementsEnforced === null) {
      hints.push("data entitlements enforced is not recorded");
    }
    if (this.props.useCase.countryList === null || this.props.useCase.countryList.length === 0) {
      hints.push("no country has been selected for the use case");
    } 
    if (this.props.useCase.systemTypeList === null || this.props.useCase.systemTypeList.length === 0) {
      hints.push("no system type has been selected for the use case");
    } 
    if (this.props.useCase.dataEntitlementsEnforced === true 
        && ((this.props.useCase.dataUseList === null || this.props.useCase.dataUseList.length === 0)
          && (this.props.useCase.processingActionList === null || this.props.useCase.processingActionList.length === 0))) {
      hints.push("data entitlements are enforced but entitlement mapping is not set");
    }
    return (
      <OwcTypography variant='title6' style={{ marginLeft: "1em" }}>
        {
          (hints.length > 0)
          ? "No applicable device could be found, please check the following:"
          : "No device matching the use case could be found"
        }
        {
          (hints.length > 0)
          ? (
            <div>
              {hints.map(hint => 
                <OwcTypography variant='title7' style={{display:"flex", flexDirection: "column", marginLeft:"1em"}}>
                  {`- ${hint}`}
                </OwcTypography>)}
            </div> 
          )
          : ""
        }
      </OwcTypography>
    )
    ;
  }
  
  /**
   * Renders the controls
   * @returns The JSX of the controls
   */
  render() {
    //decide based on the space for this control needs to be squeezed
    let fontSize = 0.75;

    let selectedDevice = null;
    if (isRecentMessage(this.state.displayDeviceDetails))
    {
      selectedDevice = extractTimedMessage(this.state.displayDeviceDetails);
    }

    // width of the div below is set to 99% to avoid the "wobbles" that can happen when expanding a table row without a slight expansion room to the side.
    return (
      <>
        <DeviceDetails
          key="DeviceDetailsDisplay"
          deviceId={selectedDevice}
          showNow={this.state.displayDeviceDetails===null?null:this.state.displayDeviceDetails}
          disableNavigation={true}
          onExclusionChange={() => this.setState({useCaseSessionId: null}, ()=>this.loadSearchResults())} />
        <div style={{display:"flex", flexDirection: "column", width:"100%", marginLeft:"1em"}}>
        <hr/>
          <OwcTypography variant="title5" style={{marginLeft:"0.7em", marginBottom: "0.5em"}}>
            -&nbsp;&nbsp;&nbsp;&nbsp;
            Devices Applicable{this.state.totalResultCount === null?" - Loading" :` - Total: ${this.state.totalResultCount} Devices` }
            {this.state.totalResultCount !== null && this.state.totalResultCount !== this.state.filteredResultCount?`, Selected ${this.state.filteredResultCount} Devices`:""}
          </OwcTypography>
          <div>
            {this.renderSearchAndSort()}
            <br />
            {this.renderTable()}
            {
              this.state.items.length > 0 && (
                <OwcPagination page={this.state.page} pageSize={this.state.rowsPerPage}
                    hidePageSize={false} total={this.state.totalPages} pageSizeOptions={[10,20,50]}
                    onPageChange={(ev) => this.handlePageChange(ev.detail)}
                    style={{display:"flex", marginLeft: "auto"}}>
                  <div slot="rows-per-page"><OwcTypography style={{fontSize:fontSize + "em"}}>Rows per Page</OwcTypography></div>
                  <div ><OwcTypography style={{fontSize:fontSize + "em"}}> {this.state.page} of {this.state.totalPages} </OwcTypography></div>
                </OwcPagination>
              )
            }
            {this.state.items.length === 0 && this.state.isLoaded && this.getEmptyAgreementListMessage()}
          </div>
        </div>
      </>
    );
  }
}

ApplicableDevices.defaultProps = {
  useCase: { useCaseId:null, name:null, countryList:[] },
  updateFlag: "",
}

export default ApplicableDevices;

